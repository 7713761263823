













import { Component, Prop, Vue } from "vue-property-decorator";
import { bus, events } from "@/bus";

@Component
export default class TopNavbar extends Vue {
  @Prop({ default: true }) hasLeftSidebar!: boolean;

  toggleSidebar() {
    bus.$emit(events.toggleSidebar);
  }
}
