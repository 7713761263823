





































import { Component, Prop, Watch } from "vue-property-decorator";
import Base from "@/components/Base.vue";
import MenuItem from "@/components/mini/MenuItem.vue";
import type { Guide } from "@/types/guides";
import { bus, events } from "@/bus";

@Component({ components: { MenuItem } })
export default class GuideSidebar extends Base {
  @Prop() guide!: Guide;
  open = true;

  mounted() {
    bus.$on(events.toggleSidebar, this.toggleSidebar);
  }

  toggleSidebar() {
    this.open = !this.open;
  }

  @Watch("isMobile")
  onMobileChange() {
    this.open = !this.isMobile;
  }

  isActive(slug: string): boolean {
    return this.$route.path.split("#")[0] === slug;
  }
  isExpanded(slug: string): boolean {
    return this.$route.path.startsWith(slug);
  }
}
