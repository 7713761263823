






import Vue from "vue";
export default class NotFoundPage extends Vue {}
// meta ref: https://stackoverflow.com/a/54271940/656188
