
































import { Component, Prop } from "vue-property-decorator";
import WebsitePage from "@/components/WebsitePage.vue";
import type { Guide, GuidePage } from "@/types/guides";

@Component({})
export default class GuideWebsitePage extends WebsitePage {
  @Prop() guide!: Guide;
  @Prop() guidePage!: GuidePage;
  get nav() {
    return this.guidePage.nav;
  }
}
