import "./router/hooks";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
// @ts-ignore
import vueHeadful from "vue-headful";
import VueClipboard from "vue-clipboard2";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "./assets/scss/app.scss";
import "@fontsource/tenor-sans";

library.add(fas, far);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconPack: "fas",
});
Vue.use(VueClipboard);
Vue.component("vue-headful", vueHeadful);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
