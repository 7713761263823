import { RouteConfig } from "vue-router";

// want to maintain these legacy routes (with in-app links) as redirects
export const redirects: RouteConfig[] = [
  {
    path: "/documentation",
    redirect: "/how-to",
  },
  {
    path: "/download",
    redirect: "/how-to/using-spectrolite/download",
  },
  {
    path: "/clean-margins",
    redirect: "/how-to/zines-and-books/layouts-zines/clean-margins",
  },
  {
    path: "/content-shift",
    redirect: "/how-to/zines-and-books/layouts-zines/content-shift",
  },
  {
    path: "/creep",
    redirect: "/how-to/zines-and-books/layouts-zines/creep",
  },
  {
    path: "/special-single-master-zines",
    redirect:
      "/how-to/zines-and-books/efficient-printing/special-single-master-zines",
  },
  {
    path: "/two-zines",
    redirect: "/how-to/zines-and-books/efficient-printing/two-zines",
  },
  {
    path: "/custom-sized-zines",
    redirect: "/how-to/zines-and-books/zine-layout-options/custom-sized-zines",
  },
  {
    path: "/magic-zines",
    redirect: "/how-to/zines-and-books/zine-layout-options/magic-zines",
  },
  {
    path: "/imposition-types",
    redirect: "/how-to/zines-and-books/layouts-zines/imposition-types",
  },
  {
    path: "/older-mac-os",
    redirect: "/how-to/using-spectrolite/download#spectrolite-and-older-macos",
  },
];
