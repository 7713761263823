















import { size } from "@/types/websiteContent";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LazyImage extends Vue {
  @Prop() url!: string;
  @Prop() size!: size | undefined;
  @Prop({ default: false }) square!: boolean;
  @Prop({ default: false }) slowFade!: boolean;
  loaded = false;

  get ratio(): string | undefined {
    return this.square
      ? "1by1"
      : this.size
      ? `${this.size.width}by${this.size.height}`
      : undefined;
  }
  formatSrcSize(src: string, size: number): string {
    return this.square
      ? `${src}?fit=fill&w=${size}&h=${size}`
      : `${src}?w=${size}`;
  }
}
