import { componentType } from "@/types/websiteContent";
import { WebsitePage } from "@/types/websitePages";
import websitePages from "./websitePages.json";

const pages: WebsitePage[] = websitePages.map((p) => {
  return {
    ...p,
    contentBlocks: p.contentBlocks.map((c) => {
      return { ...c, componentType: c.componentType as componentType };
    }),
  };
});
export default pages;
