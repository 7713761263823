import { WebsitePage } from "./websitePages";

export interface Guide {
  slug: string;
  sections: GuideSection[];
  mainPage: WebsitePage;
}
export interface GuideSection {
  name: string;
  children: GuidePage[];
  isDraft: boolean;
}

export interface GuidePage {
  name: string;
  page: WebsitePage;
  slug: string;
  isDraft: boolean;
  underConstruction: boolean;
  children: GuidePage[];
  nav: pageNav;
}

export interface link {
  to: string;
  name: string;
  parentName: string;
}
export interface pageNav {
  previous?: link;
  next?: link;
}

export function join(a: string, b: string): string {
  return [a, b]
    .map(function (i) {
      return i.replace(/(\/$)/, "");
    })
    .join("/");
}
