
























import { Component, Prop, Vue } from "vue-property-decorator";
import type { WebsiteContent } from "@/types/websiteContent";
import NiceContent from "@/components/mini/NiceContent.vue";

@Component({ components: { NiceContent } })
export default class ContentBlock extends Vue {
  @Prop() content!: WebsiteContent;
  @Prop() index!: number;

  get classList(): string {
    return [
      this.content.componentType,
      this.content.classes,
      this.isMessage ? "message" : "",
    ].join(" ");
  }
  get isMessage(): boolean {
    return this.content.componentType === "message";
  }
}
